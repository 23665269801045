import logo from './logo.svg';
import './App.css';

export default function App()
{
    return (
        <div className="App">
            <header className="App-header">

                <img src={logo} className="App-logo" alt="logo" />

                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>

                <MyButton />

                <CapsLocak text="texto 01" />


                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>

            </header>

        </div>
    );
}

function MyButton()
{
    return (
        <button>I'm a button</button>
    );
}

function CapsLocak(props)
{
    let text = props.text;
    text = text.toUpperCase();

    return (
        <div>ALO {text}</div>
    );
}
